@font-face{
    font-family: 'FSJack';
    src:url('/assets/account-management/fonts/fs-jack/FSJack.eot');
    src:url('/assets/account-management/fonts/fs-jack/FSJack?#iefix') format('embedded-opentype'),
    url('/assets/account-management/fonts/fs-jack/FSJack.woff') format('woff'),
    url('/assets/account-management/fonts/fs-jack/FSJack.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;    
}
@font-face{
    font-family: 'FSJack-Medium';
    src:url('/assets/account-management/fonts/fs-jack/FSJack-Medium.eot');
    src:url('/assets/account-management/fonts/fs-jack/FSJack-Medium?#iefix') format('embedded-opentype'),
    url('/assets/account-management/fonts/fs-jack/FSJack-Medium.woff') format('woff2'),
    url('/assets/account-management/fonts/fs-jack/FSJack-Medium.woff') format('woff'),
    url('/assets/account-management/fonts/fs-jack/FSJack-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;    
}
@font-face{
    font-family: 'FSJack-Bold';
    src:url('/assets/account-management/fonts/fs-jack/FSJack-Bold.eot');
    src:url('/assets/account-management/fonts/fs-jack/FSJack-Bold?#iefix') format('embedded-opentype'),
    url('/assets/account-management/fonts/fs-jack/fs_jack-bold.woff') format('woff2'),
    url('/assets/account-management/fonts/fs-jack/fs_jack-bold.woff') format('woff'),
    url('/assets/account-management/fonts/fs-jack/FSJack-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;    
}
@font-face{
    font-family: 'FSJackPoster';
    src:url('/assets/account-management/fonts/fs-jack/FSJackPoster.eot');
    src:url('/assets/account-management/fonts/fs-jack/FSJackPoster?#iefix') format('embedded-opentype'),
    url('/assets/account-management/fonts/fs-jack/FSJackPoster.woff') format('woff'),
    url('/assets/account-management/fonts/fs-jack/FSJackPoster.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;    
}

