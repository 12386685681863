$image-path: "assets/account-management/images/icons/";
$image-list: (
   "dbs-accepted": "dbs-accepted.png",
   "dbs-suspended": "dbs-suspended.png",
   "dbs-expiring-soon": "dbs-expiring-soon.png",
   "dbs-expired": "dbs-expired.png",
   "dbs-in-progress": "dbs-in-progress.png",
   "dbs-suspended-error": "dbs-suspended-error.png",
   "dbs-referred-to-fa":"dbs-referred-to-fa.png",
   "dbs-initiated":"dbs-initiated.png",
   "dbs-inprogress" : "dbs-inprogress.png",
);

@each $key, $val in $image-list {
    .image-#{$key} {
        background : url(#{$image-path}#{$val})  no-repeat center center;
    }
}
