html,body{ overflow-x:hidden; }
body{  font-family: Arial,Helvetica,sans-serif; min-width: 320px; background:#fff !important; padding: 0; }
h1,h2,h3,h4,h5,h6, label{ margin: 0; font-weight:normal;}
ol, ul, li {	margin: 0;	padding: 0;	list-style: none;}
p{ margin: 0!important;}
a,a:link,a:hover,a:visited,a:active{text-decoration: none; }
label{ -webkit-user-select: none; /* webkit (safari, chrome) browsers */    -moz-user-select: none; /* mozilla browsers */    -khtml-user-select: none; /* webkit (konqueror) browsers */    -ms-user-select: none; /* IE10+ */}
.noscroll{ overflow:hidden; height:100%; position:relative;}
.red{ color:#E1261C;}
.current-page-title{display:none; color: #222222;	font-family: "FSJack-Medium", arial, helvetica, sans-serif;	font-size: 18px; line-height: 22px;padding: 20px 15px;}
.banner{border:none !important}
.fa-navigation .global-navigation__primary-list-item{
  position: relative;
}
.preference-menu-tooltip,.changepass-menu-tooltip,.diversityAndInclusion-menu-tooltip{
  border-radius: 0;
  background-color: #e0e0e0;
  padding: 15px 15px 1px;
  font-family: FSJack-Medium, arial, helvetica, sans-serif;
  font-size: 12px!important;
  line-height: 14px!important;
  position: absolute!important;
  color: #011e41;
  width: 267px!important;
  top: 43px;
  margin: 0;
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.24);
  left: 0;
  display: none;
}
.preference-menu-tooltip:before,.changepass-menu-tooltip:before,.diversityAndInclusion-menu-tooltip:before {
  top: -10px;
  left: 70px;
  border-left: 12px solid transparent;
  border-bottom: 12px solid #e0e0e0;
  border-right: 12px solid transparent;
  transform: rotate(0);
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.changepass-menu-tooltip:before{
  left:85px;
}
@media (max-width: 768px) and (min-width: 360px) {
  .body-content {
    margin-top: 60px !important;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .container {
    max-width: 100%;
  }
}
/*Till Tablet Portrait*/
@media (max-width: 991px) {
  body{  padding: 0; }
    .current-page-title{display: block;text-transform: uppercase;}
    .preference-menu-tooltip,.changepass-menu-tooltip,.diversityAndInclusion-menu-tooltip{
      top:46px;
    }
    .preference-menu-tooltip:before,.changepass-menu-tooltip:before,.diversityAndInclusion-menu-tooltip:before{
      left:15px;
    }
    .fa-navigation .global-navigation__primary-list-item{
        position: relative;
    }
    .fa-navigation .global-navigation__primary-list-item:not([href]):not([tabindex]){
        color:#fff;
    }
    .fa-navigation .global-navigation__primary-link:after{
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(../../account-management/images/icons/arrow.svg) no-repeat;
    background-size: cover;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 6px;
    }
    .logo-container{
        display:block;
  }
  .topbar__menu-item.mobileShow{
    display: block;
  }
  #wholegame.topbar__menu-item.mobileShow .topbar__link{
    border-top: 0px !important;
  }

  #wholegame .topbar__link, #refrereg .topbar__link{
    padding-left: 0px;
    line-height: 16px;
    font-family: "FSJack", arial, helvetica, sans-serif;
  }

  .fa-navigation .topbar__link.mobileOne{
    padding-left: 0px !important;
    line-height: 16px !important;
    font-family: "FSJack", arial, helvetica, sans-serif !important;
    text-transform: uppercase;
  } 

  .topbar__menu-item.bentobox{
    display: none !important;
  }
  .fa-navigation .global-navigation__primary-link.child-disable .text{
    opacity: 0.3; filter: alpha(opacity=30);
  }

}

@media (min-width: 992px) {
    /* .fa-navigation{border-bottom:2px solid #E1261C;} */
    .fa-navigation .global-navigation__primary-menu{ padding: 0 !important}
    .fa-navigation .global-navigation__primary-link .text{font-family: "FSJack-Bold", arial, helvetica, sans-serif; font-size: 14px;line-height: 17px;text-transform: uppercase;font-weight: normal; padding:0 15px}
    .fa-navigation .global-navigation__primary-link.child-disable .text{color:rgba(1,30,60,0.4);}
    .fa-navigation .global-navigation__primary-link.child-disable{cursor: default}
      /*.fa-navigation li:first-child .global-navigation__primary-link:before{border: none !important;}*/
    /*.fa-navigation .global-navigation__primary-link::after{border: none !important;}*/
    .fa-navigation .global-navigation__primary-list-item.current-section .global-navigation__primary-link{background-color:#E1261C !important; height:45px;color:#fff !important}
    .fa-navigation li:first-child .global-navigation__primary-link:before{border-color:transparent}
    .fa-navigation .global-navigation__primary-list-item.current-section .global-navigation__primary-link:before{border-color: #E1261C transparent transparent !important;height:45px;}
    .fa-navigation .global-navigation__primary-list-item.current-section .global-navigation__primary-link:after{border-color: transparent transparent #E1261C  !important;height:45px;}
    .fa-navigation .global-navigation__primary-link,.fa-navigation .topbar__link{cursor:pointer}
    .fa-navigation .topbar__extramenu .topbar__link{padding:9px 5px 4px 5px !important;font-size:12px; font-weight:normal}
    .fa-navigation .global-navigation__primary-link:not([routerlink]) { cursor: default }
  .fa-navigation .global-navigation__primary-list-item {
    -webkit-box-flex: initial !important;
    -moz-box-flex: initial !important;
    -webkit-flex: initial !important;
    -moz-flex: initial !important;
    -ms-flex: initial !important;
    flex: 0 0 auto !important;
    -webkit-flex-basis: auto !important;
    -moz-flex-basis: auto !important;
    flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;    
  }
  /*.fa-navigation .global-navigation__primary-link{margin-left: 50px !important;}*/
  .logo-container {
    display: none !important;
  }
  .fa-navigation .global-navigation__primary-list-item:first-child > a {
    margin-left: 0px !important;
  }
  .fa-navigation .global-navigation__primary-link{
    color:#011E41 !important;
  }
  .topbar__menu-item.bentobox .topbar__link.bento-content:before{
    transform: translateY(-50%);
    display: block;
    width: 16px;
    height: 20px;
    background: url(../../account-management/images/icons/Bento.svg) center no-repeat;
    top: 15px;
  }

  /* .fa-navigation .topbar__menu-item.bentobox{
    margin-left: -30px !important;
  }  */

  .bentomenu{
    /* left:-15px; */
    width: calc(100% - 76%);
    padding: 23px 20px;
    border: 1px solid #768791;
    border-top: 3px solid #0068b2;
    box-shadow: 0 2px 4px rgba(0,0,0,.6);
    transition: none;
    top: 30px;
    bottom: auto;
    background: #333;    
    position: absolute;
    z-index: 130;
  }
  .topbar__menu-item.mobileShow{
    display: none !important;
  }

  .bentomenu .topbar__submenu-list-items{
    border:none !important;
    width:100% !important;
    float: left !important;
  }
  .bentomenu #AmDesktop.topbar__submenu-list-item{
    padding-bottom: 25px;
  }

  .bentomenu .topbar__submenu-list-item a{
    padding: 0px;
    line-height: 16px;
  }

  .bentomenu .topbar__submenu-list-item a:after{
    transform: translateY(-50%);
    background: url(../../account-management/images/icons/arrow.svg) no-repeat;
    background-size: cover;
    content: "";
    height: 14px;
    width: 8px;
    margin-top: 10px;
    float: right;
  }

  .fa-navigation .topbar__link.mobileOne:before{
    transform: translateY(-50%) !important;
    display: block !important;
    width: 16px !important;
    height: 20px !important;
    background: url(../../account-management/images/icons/Bento.svg) center no-repeat !important;
    top: 15px !important;
  }

}
@media (min-width: 1200px) {
    .fa-navigation .global-navigation__primary-menu{ padding: 0 !important}
  .fa-navigation .global-navigation__primary-list-item:first-child > a {
    margin-left: 0px !important;
  }
}

.fa-navigation .global-navigation__primary-list-item, .fa-navigation .global-navigation__primary-link {
  width: auto !important;
}
