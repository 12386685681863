/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/account-management/css/fonts.css";
@import "assets/account-management/css/common.css";

// for accordians
#accordian-section{
.mat-accordion{
  background-color: #f7f7f7;
}
.mat-accordion .mat-expansion-panel{
  border-radius: 0px;
  background-color: #f7f7f7;  
  box-shadow: none;
  font-size: 14px;
  border-bottom: 0.5px solid rgba(0,0,0,0.24);
}
.mat-accordion .mat-expansion-panel.mat-expaneded{
background-color: #EEEEEE;
}
.mat-expansion-panel-header{
  padding: 0px !important;
  height: auto;
}
.mat-expansion-panel.mat-expanded{
  background-color: #EEEEEE;
}
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover{
  background-color: #f7f7f7;
}
.mat-expansion-indicator{  
  margin-right: 18px; 
}
.mat-expansion-panel-body{
  padding: 0px !important;
  margin-left: 5px;
}
.mat-panel-title{
  font-family: "FSJack", arial, helvetica, sans-serif;
  font-size: 14px;
}
.mat-expansion-panel-header-description, .mat-expansion-indicator::after{
  color:black
}

}//end for accordians 


.mat-mdc-checkbox-checked .mdc-checkbox__background{
  background-color: #011e41 !important;
}

.mdc-checkbox__background{
  border-color: #011e41 !important;
}

.mat-mdc-checkbox-checked .mdc-checkbox__checkmark{
  color: white;
}

.mat-ripple.mat-mdc-checkbox-ripple{
  opacity: 0 !important;
}

.mdc-checkbox .mdc-checkbox__ripple{
  opacity: 0 !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate)~.mdc-checkbox__background {
  border-color: var(--mdc-checkbox-disabled-unselected-icon-color) !important;
}

@media only screen and (max-width: 991px) {
  .fa-navigation--active .navigation-container {
    width: 100% !important;
  }
}

.mat-select-panel { 
  max-width: 245px !important;
}

#mydropdownoptionMobile{

.mat-expansion-panel-header.mat-expanded
  .mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:hover {
  .mat-expansion-indicator::after {
    color: #ffffff !important;
  }
}

.mat-expansion-panel-header.mat-expanded {
  .mat-expansion-indicator::after {
    color: #ffffff !important;
  }
  .mat-expansion-panel-header-description,
  .mat-expansion-panel-header-title {
    color: #ffffff !important;
  }
}

}